
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container;
  display: none;

  :global(.gather) & {
    background-color: $gather-free-acres-orange;
  }

  :global(.go-solutions) & {
    background-color: $go-yellow;
    color: $go-charcoal;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    background-color: $color-white;
    border: 1px solid $now-brown;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 30;
  }

  :global(.petcurean) & {
    background-color: $petcurean-light-tan;
  }

  :global(.summit) & {
    background-color: $summit-gold;
    color: $summit-white;
  }
}

.visible {
  display: block;
}

.wrapper {
  display: flex;
  position: relative;
  padding: 0 30px;

  @include at-breakpoint(medium) {
    padding: 0 40px;
  }
}

.text {
  width: 100%;
  text-align: center;
  padding: 10px 0;

  @include at-breakpoint(medium) {
  }

  p {
    @include p2;

    :global(.gather) & {
      color: $gather-black;
      font-size: 16px;
    }

    :global(.now-fresh) & {
      color: $now-brown;
    }

    :global(.petcurean) & {
      color: $petcurean-grey;
      font-size: 16px;
    }
  }

  a {
    text-decoration: underline;

    :global(.gather) & {
      color: $gather-black;
      svg {
        path {
          fill: $gather-black;
        }
      }

      &:hover {
        color: $petcurean-grey;

        svg {
          path {
            fill: $petcurean-grey;
          }
        }
      }
    }

    :global(.go-solutions) & {
      color: $go-charcoal;

      &:hover {
        color: $go-semidark-grey;

        svg {
          path {
            fill: $go-semidark-grey;
          }
        }
      }
    }

    :global(.now-fresh) & {
      color: $now-brown;

      svg {
        path {
          fill: $now-brown;
        }
      }

      &:hover {
        svg {
          path {
            stroke: $now-brown;
          }
        }
      }
    }

    :global(.petcurean) & {
      color: $petcurean-grey;

      svg {
        path {
          fill: $petcurean-grey;
        }
      }

      &:hover {
        color: $petcurean-orange !important;

        svg {
          path {
            fill: $petcurean-orange !important;
          }
        }
      }
    }

    :global(.summit) & {
      color: $summit-dark-blue;

      svg {
        path {
          fill: $summit-dark-blue;
        }
      }

      &:hover {
        color: $summit-blue;

        svg {
          path {
            fill: $summit-blue;
          }
        }
      }
    }

    // styles for circular link
    :global(.go-solutions) &,
    :global(.now-fresh) &,
    :global(.petcurean) & {
      &:not(href) {
        color: inherit;

        &:hover {
          color: inherit;
        }

        svg {
          display: none;
        }
      }
    }
  }
}

.close {
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 1;
  padding: 2px 0;

  :global(.locale-he) & {
    right: auto;
    left: 0;
  }

  :global(.gather) & {
    svg {
      path {
        fill: $gather-black;
      }
    }

    &:hover {
      svg {
        path {
          fill: $petcurean-grey;
        }
      }
    }
  }

  :global(.go-solutions) & {
    svg {
      path {
        fill: $go-charcoal;
      }
    }

    &:hover {
      svg {
        path {
          fill: $go-semidark-grey;
        }
      }
    }
  }

  :global(.now-fresh) & {
    svg {
      path {
        fill: $now-brown;
      }
    }

    &:hover {
      svg {
        path {
          fill: $now-brown;
          stroke: $now-brown;
        }
      }
    }
  }

  :global(.petcurean) & {
    svg {
      path {
        fill: $petcurean-grey;
      }
    }

    &:hover {
      svg {
        path {
          fill: $petcurean-orange;
        }
      }
    }
  }

  :global(.summit) & {
    svg {
      path {
        fill: $summit-dark-blue;
      }
    }

    &:hover {
      svg {
        path {
          fill: $summit-blue;
        }
      }
    }
  }
}
