
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.search-hits {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  min-height: 300px;
  overflow-y: auto;
  height: calc(100dvh - 88px);

  :global(.go-solutions) & {
    background-color: $color-black;
  }

  :global(.now-fresh) & {
    background-color: $now-tan;
    box-shadow: 0 0 0 1px $now-brown;
  }

  :global(.petcurean) & {
    background-color: $petcurean-orange;
  }

  @include at-breakpoint(medium) {
    :global(.now-fresh) & {
      height: calc(100dvh - 112px);
    }
  }

  @include at-breakpoint(large) {
    height: auto;
    max-height: calc(100dvh - 88px);

    :global(.now-fresh) & {
      height: auto;
    }
  }

  &.open {
    display: block;
  }
}

.container {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;

  @include at-breakpoint(large) {
    width: calc(100% - 64px);
    margin: 32px auto 63px;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-transform: none;
  text-align: center;
  padding-bottom: 20px;

  // only display 8 results on mobile
  > :nth-child(n + 9) {
    display: none;
  }

  @include at-breakpoint(medium) {
    grid-template-columns: repeat(3, 1fr);

    // display 9 results on tablet
    > :nth-child(n + 9) {
      display: block;
    }
  }

  @include at-breakpoint(x-large) {
    grid-template-columns: repeat(4, 1fr);

    // display 4 results on desktop
    > :nth-child(n + 5) {
      display: none;
    }
  }

  :global(.now-fresh) & {
    grid-gap: 1px;

    > * {
      border: none;
      box-shadow: 0 0 0 1px $now-brown;
    }
  }

  :global(.petcurean) & {
    gap: 12px;
    margin: 0 15px;

    @include at-breakpoint(medium) {
      margin: 0;
    }

    > div * {
      color: $color-white;
      fill: $color-white;
    }

    > div a:hover {
      * {
        color: $petcurean-dark-tan;
        path {
          fill: $petcurean-dark-tan !important; // override the fill color
        }
      }
    }
  }
}

.no-results {
  grid-column: 1 / -1;

  :global(.now-fresh) & {
    box-shadow: none;
  }

  p {
    @include p1();
    margin: 10px 0 20px;

    :global(.go-solutions) & {
      color: $color-white;
    }

    :global(.now-fresh) & {
      color: $now-brown;
    }

    :global(.petcurean) & {
      color: $color-white;
    }
  }
}

.view-all {
  display: block;
  margin: 20px auto;
  cursor: pointer;
  text-decoration: underline;
  position: relative;

  @include at-breakpoint(large) {
    margin: 20px 0;
  }

  :global(.go-solutions) & {
    @include p2();
    text-decoration: none;
    color: $color-white;

    &::after {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $go-yellow;
    }

    &:hover {
      &::after {
        background-color: $color-white;
      }
    }
  }

  :global(.petcurean) & {
    color: $color-white;

    &:hover {
      color: $petcurean-grey-light;
    }
  }

  :global(.now-fresh) & {
    font-family: 'Stag', sans-serif;
    font-weight: 400;

    &:hover {
      font-weight: 500;
    }
  }
}

.popular-products {
  grid-column: span 3;
  padding-bottom: 0 !important;
}
