
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  grid-column: 1 / -1;

  @include at-breakpoint(large) {
    grid-column: span 8;
  }

  @include at-breakpoint(x-large) {
    grid-column: span 9;
  }
}

.related-products {
  :global(.go-solutions) & {
    background-color: $color-white;

    h2,
    p,
    h3 {
      color: $color-black;
    }

    button {
      background-color: $go-yellow;

      &:hover {
        background-color: $go-light-yellow;
      }
    }

    :global(.slick-dots) {
      button {
        background-color: transparent;

        &::before {
          background-color: $go-gray;
        }
      }

      :global(.slick-active) {
        button {
          &::before {
            background-color: $go-yellow;
          }
        }
      }
    }
  }
}
