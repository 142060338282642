
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $color-black;

  :global(.go-solutions) & {
    padding: 0 16px;

    @include at-breakpoint(large) {
      padding: 0;
    }
  }

  :global(.now-fresh) & {
    border-color: $now-brown;
  }

  :global(.petcurean) & {
    border-color: $petcurean-grey;
  }
}

.clear-cta-desktop {
  display: none;
  min-width: 0;

  @include at-breakpoint(large) {
    &.visible {
      display: inline-block;
      margin-right: 24px;

      :global(.locale-he) & {
        margin-right: 0;
        margin-left: 24px;
      }
    }
  }

  // Share the same styling as clear-cta
  :global(.go-solutions) & {
    p {
      @include p3();
      font-weight: 700;
      text-transform: capitalize;
      padding-bottom: 2px;
    }
  }

  :global(.now-fresh) & {
    color: $now-brown;
    border: 0;
    border-bottom: 1px solid $now-brown;

    &:hover {
      border-bottom: 3px solid $now-brown;
    }

    p {
      @include p3();
      font-weight: 500;
      font-size: 16px;
    }
  }

  :global(.petcurean) & {
    padding: 0;
    color: $petcurean-orange;

    &:hover {
      color: $petcurean-dark-orange;
      text-decoration: underline;
    }
  }

  :global(.summit) & {
    color: $summit-black;

    &:after {
      background-image: $summit-dark-gradient;
    }

    p {
      @include p3();
      @include bold();
      padding-bottom: 2px;
    }
  }
}

.title {
  padding: 40px;

  :global(.go-solutions) & {
    padding: 40px 40px 40px 0px;
    @include h4;
  }

  :global(.petcurean) & {
    padding: 24px 16px;
  }

  @include at-breakpoint(large) {
    :global(.petcurean) & {
      padding: 40px 0;
    }
    margin-right: auto;

    :global(.locale-he) & {
      margin-right: 0;
      margin-left: auto;
    }
  }
}

.category-title {
  @include eyebrow();
  padding: 40px;
  width: 100%;
  border-bottom: 1px solid $color-black;
  color: inherit; // Fixes iOS Safari bug where color is not inherited

  :global(.go-solutions) &,
  :global(.petcurean) & {
    padding: 24px 16px;
    border-bottom: none;
    width: calc(100% - 4px);

    @include at-breakpoint(large) {
      padding: 24px 0;
    }
  }

  :global(.now-fresh) & {
    border-color: $now-brown;
    font-weight: 400;
    color: $now-brown;
  }

  :global(.summit) & {
    color: $summit-black;
  }

  span {
    display: flex;
    align-items: center;
    text-align: start;
    width: 100%;

    svg {
      transform: scale(0.5) rotate(-90deg);
      margin-inline-start: auto;
      pointer-events: none;
      transition: transform 0.25s;

      @include reducedMotion() {
        transition: none;
      }

      path {
        :global(.go-solutions) & {
          fill: $color-black;
        }

        :global(.now-fresh) & {
          fill: $now-brown;
        }

        :global(.petcurean) & {
          fill: $petcurean-grey;
        }

        :global(.summit) & {
          fill: $summit-blue;
        }
      }
    }
  }

  &.open {
    svg {
      transform: scale(0.5) rotate(-270deg);
      margin-inline-start: auto;
      pointer-events: none;

      path {
        :global(.go-solutions) & {
          fill: $go-teal;
        }

        :global(.now-fresh) & {
          fill: $now-brown;
        }

        :global(.petcurean) & {
          fill: $petcurean-orange;
        }
      }
    }
  }
}

.refinement-container {
  background-color: $color-white;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  height: 0;

  :global(.go-solutions) & {
    border-bottom: 1px solid $color-black;
  }

  :global(.petcurean) & {
    background-color: transparent;
    border-bottom: 1px solid $petcurean-grey;
  }

  &.open {
    :global(.now-fresh) & {
      border-bottom: 1px solid $now-brown;
    }
  }
}

.refinement-list {
  margin: 40px;

  :global(.go-solutions) &,
  :global(.petcurean) & {
    margin: 0;
    padding: 0 16px 24px;
    padding-bottom: 24px;

    @include at-breakpoint(large) {
      padding: 0 0 24px;
    }
  }
}

.label {
  @include p1();
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;

  :global(.go-solutions) & {
    @include p2();
  }

  :global(.petcurean) & {
    @include p2();
  }
}

.checkbox {
  cursor: pointer;
  height: 25px;
  width: 25px;
  margin-inline-end: 12px;
  appearance: none;
  display: inline-table;
  border: none;
  background-color: transparent;

  :global(.tabbing) &:focus {
    &:after {
      box-shadow: 0 0 2px 2px $outline;
    }
  }

  @media (hover: hover) {
    &:hover {
      &:after {
        border: none;

        :global(.gather) & {
          background-color: $gather-green;
        }

        :global(.go-solutions) & {
          background-color: $go-teal;
        }

        :global(.now-fresh) & {
          background-color: $now-grey;
          border: 1px solid $now-brown;
        }

        :global(.summit) & {
          background-color: $summit-blue;
        }
      }
    }
  }

  &:checked {
    &:after {
      border: none;

      :global(.gather) & {
        background-color: $gather-green;
      }

      :global(.go-solutions) & {
        background-color: $go-teal;
      }

      :global(.now-fresh) & {
        background-color: $now-grey;
        border: 1px solid $now-brown;
      }

      :global(.petcurean) & {
        background-color: $petcurean-orange;
      }

      :global(.summit) & {
        background-color: $summit-blue;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: 5px;
      top: 5px;
      z-index: 1;
      width: 15px;
      height: 8px;
      border-left: 2px solid $color-white;
      border-bottom: 2px solid $color-white;
      transform: rotate(-45deg);

      :global(.now-fresh) & {
        border-color: $now-brown;
      }

      :global(.locale-he) & {
        left: auto;
        right: 5px;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid $color-black;
    border-radius: 4px;

    :global(.locale-he) & {
      left: auto;
      right: 0;
    }

    :global(.gather) & {
      border: 0;
      background-color: rgba($gather-black, 0.05);
    }

    :global(.petcurean) & {
      border: 0;
      background-color: rgba($petcurean-grey, 0.07);
    }

    :global(.summit) & {
      border: 0;
      background-color: rgba($summit-black, 0.07);
    }
  }
}

.count {
  display: none;
}

.container {
  position: fixed;
  height: 100%;
  width: 100vw;
  left: -100vw;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s;
  z-index: $filters-z-index;

  :global(.go-solutions) & {
    background-color: $color-white;
  }

  :global(.petcurean) & {
    background-color: $petcurean-white;
  }

  &.is-open {
    left: 0;
    opacity: 1;
    visibility: visible;
  }

  @include at-breakpoint(medium) {
    position: static;
    opacity: 1;
  }

  @include at-breakpoint(large) {
    .apply-filters-btn {
      display: none;
    }
  }
}

.interactive-headline-container {
  display: flex;
  padding: 37px 24px 32px;
  width: 100%;
  align-items: center;

  @include at-breakpoint(large) {
    display: none;
  }

  .caret {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0.6);
    pointer-events: none;
  }
}

.interactive-headline {
  @include eyebrow();
  width: 100%;
  flex: 1 1 calc(100% - 100px);
  position: relative;
  color: inherit; // Fixes iOS Safari bug where color is not inherited
}

.apply-filters-btn {
  @include eyebrow();
  font-weight: 500;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: $filters-z-index + 1;
  color: inherit;

  @include at-breakpoint(medium) {
    width: 50vw;
  }

  :global(.locale-he) & {
    left: auto;
    right: 0;
  }

  span {
    padding: 32px 66px;
  }

  :global(.go-solutions) & {
    background-color: $go-yellow;

    &:disabled {
      background-color: $go-light-grey;
      color: $color-black;
    }
  }

  :global(.now-fresh) & {
    font-weight: 500;
    color: $now-brown;
    background-color: $color-white;
    border-top: 1px solid $now-brown;

    &:disabled {
      background-color: $now-grey;
      color: $now-brown;
    }
  }

  :global(.petcurean) & {
    background-color: $petcurean-orange;
    color: $color-white;

    &:disabled {
      background-color: $petcurean-grey-light;
      color: $petcurean-grey;
    }
  }

  :global(.summit) & {
    background-color: $summit-blue;
    color: $summit-white;

    &:disabled {
      background-color: $summit-grey;
      color: $summit-black;
    }
  }
}

.filters-scroll-container {
  height: 100%;
  max-height: calc(100% - 190px);
  overflow-y: auto;
  padding-bottom: 100px; // Make space for fixed button

  @include at-breakpoint(large) {
    max-height: none;
    padding-bottom: 0;
  }
}

.clear-cta {
  display: none;
  min-width: 0;

  &.visible {
    display: inline-block;
    position: absolute;
    right: 18px;

    :global(.locale-he) & {
      right: auto;
      left: 18px;
    }

    @include at-breakpoint(large) {
      position: static;
      margin-top: 18px;

      :global(.summit) & {
        position: relative;
      }
    }

    :global(.go-solutions) & {
      p {
        font-weight: 700;
      }
    }

    :global(.now-fresh) & {
      border-bottom: 1px solid $now-brown;

      &:hover {
        border-bottom: 3px solid $now-brown;
      }

      p {
        font-weight: 500;
        font-size: 16px;
      }
    }

    :global(.summit) & {
      color: $summit-black;
    }
  }

  :global(.go-solutions) & {
    p {
      @include p3();
      text-transform: capitalize;
      padding-bottom: 2px;
    }
  }

  :global(.now-fresh) & {
    margin-top: 2px;
    padding-bottom: 2px;
    border: none;
    color: $now-brown;

    @include at-breakpoint(large) {
      margin-top: 22px;
    }

    p {
      @include p3();
    }
  }

  :global(.petcurean) & {
    padding: 0;
    color: $petcurean-orange;

    &:hover {
      color: $petcurean-dark-orange;
      text-decoration: underline;
    }
  }

  :global(.summit) & {
    &:after {
      background-image: $summit-dark-gradient;
    }

    p {
      @include p3();
      @include bold();
      padding-bottom: 2px;
    }
  }
}
