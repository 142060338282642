
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.cta-button {
  min-width: 120px;
  margin-inline-end: 12px;
  cursor: pointer;
  width: fit-content;
  overflow: hidden;

  $a11ybtn-padver: 20px;
  $a11ybtn-padhor: 16px;

  :global(.go-solutions) & {
    background-color: $go-yellow;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 18px), calc(100% - 18px) 100%, 0 100%);
    font-family: 'Gotham', sans-serif;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.5;

    &:hover {
      background-color: $go-light-yellow;
    }
  }

  :global(.now-fresh) & {
    background-color: $color-white;
    color: $now-brown;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1px;
    border-radius: 28px;
    border: 1px solid $now-brown;
    font-family: 'Stag', sans-serif;

    &:hover {
      background-color: $now-grey;
    }
  }

  :global(.summit) & {
    font-family: 'Bloc Pro Extra Condensed', sans-serif;
    color: $summit-white;
    background-color: $summit-blue;
    font-size: 18px;
    letter-spacing: 1.5px;
    line-height: 1;

    &:hover {
      background-color: $summit-light-blue;
    }
  }

  div {
    width: 100%;
    height: 100%;
    padding: $a11ybtn-padver $a11ybtn-padhor;

    &:hover {
      :global(.go-solutions) & {
        color: $color-black;
      }
    }
  }

  :global(.ps-disabled) {
    :global(.go-solutions) & {
      background-color: $go-gray;
    }

    :global(.now-fresh) & {
      background-color: $now-grey;
    }

    :global(.summit) & {
      background-color: $summit-black;
    }
  }
}
