
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.site-search {
  margin-inline-start: auto;

  @include at-breakpoint(large) {
    margin-inline-start: 0;
  }
}

.search-box {
  display: none;

  .search-form {
    display: flex;
    align-items: center;
    position: relative;
  }

  .search-input {
    width: 165px;
    border-radius: 100px;
    padding: 7px 30px 7px 40px;
    border: 1px solid $color-black;
    background-color: $color-white;

    :global(.go-solutions) & {
      border: none;
    }

    :global(.now-fresh) & {
      border: 1px solid $now-brown;
    }

    :global(.petcurean) & {
      border: 1px solid $petcurean-grey;
    }
  }

  .search-submit {
    position: absolute;
    left: 11px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    pointer-events: all;

    svg {
      width: 17px;
      pointer-events: none;

      path {
        :global(.go-solutions) & {
          fill: $go-charcoal;
        }

        :global(.now-fresh) & {
          fill: $now-brown;
        }

        :global(.petcurean) & {
          fill: $petcurean-grey;
        }
      }
    }
  }

  .search-reset {
    position: absolute;
    right: 11px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    svg {
      max-width: 17px;
      pointer-events: none;

      * {
        fill: $color-black;

        :global(.go-solutions) & {
          fill: $go-charcoal;
        }

        :global(.now-fresh) & {
          fill: $now-brown;
        }

        :global(.petcurean) & {
          fill: $petcurean-grey;
        }
      }
    }
  }

  .search-loading {
    position: absolute;
    right: 40px;
    width: 17px;
    display: inline-flex;
    align-items: center;

    svg {
      width: 17px;
      animation: spin 1s linear infinite;

      path {
        stroke: $color-black;

        :global(.go-solutions) & {
          stroke: $go-charcoal;
        }

        :global(.now-fresh) & {
          stroke: $now-brown;
        }

        :global(.petcurean) & {
          stroke: $petcurean-grey;
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// search open state
.open {
  margin-inline-start: 0;

  .search-box {
    display: block;
  }

  .search-input {
    max-width: 300px;
    width: 100%;

    @include at-breakpoint(medium) {
      width: 400px;
    }

    @include at-breakpoint(x-large) {
      width: 500px;
    }
  }
}

.hits {
  display: none;
}

.close-search {
  color: inherit; // fixes iOS Safari bug where the color is not inherited

  :global(.go-solutions) & {
    color: $color-white;
    text-transform: uppercase;
    font-size: 14px;
  }

  :global(.petcurean) & {
    color: $color-white;
  }
}

.searchToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
  border-radius: 100%;
  border: 1px solid $color-black;
  background-color: $color-white;
  cursor: pointer;

  :global(.go-solutions) & {
    border: none;
  }

  :global(.now-fresh) & {
    border: 1px solid $now-brown;
  }

  :global(.petcurean) & {
    border: 1px solid $petcurean-grey;
  }

  svg {
    width: 17px;
    height: 17px;
    fill: $color-black;

    :global(.go-solutions) & {
      fill: $go-charcoal;
    }

    :global(.now-fresh) & {
      fill: $now-brown;
    }

    :global(.petcurean) & {
      fill: $petcurean-grey;
    }
  }
}
