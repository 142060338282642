
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  min-height: 600px;
  @include grid-container();
  background-color: $color-white;
  padding-bottom: 57px;
  grid-template-rows: auto 1fr;

  @include at-breakpoint(medium) {
    padding-bottom: 57px;
  }

  @include at-breakpoint(large) {
    padding-bottom: 97px;
  }

  :global(.go-solutions) & {
    grid-row-gap: 34px;
  }

  :global(.now-fresh) & {
    grid-column-gap: 16px;
    grid-row-gap: 10px;
    min-height: auto;
    outline: 1px solid $now-brown;
    background-color: $now-tan;

    @include at-breakpoint(large) {
      grid-column-gap: 25px;
    }
  }
}

.results {
  @include p3();
  grid-column: span 6;
  display: flex;
  justify-content: flex-end;
  margin: 36px 0 -14px;

  @include at-breakpoint(medium) {
    margin-top: 39px;
  }

  @include at-breakpoint(large) {
    margin: 67px 0 -6px;
    grid-column: span 12;
  }

  :global(.gather) & {
    margin-bottom: 27px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $petcurean-grey;
  }

  :global(.go-solutions) & {
    color: $go-semidark-grey;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    font-size: 14px;
    margin: 36px 0 18px;

    @include at-breakpoint(large) {
      margin-bottom: 26px;
    }
  }

  :global(.petcurean) & {
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $petcurean-grey;
  }
}

.pagination {
  @include p1();
  grid-column: span 6;
  margin-top: 6px;
  border-top: 2px solid $go-light-opaque-black;
  padding-top: 44px;

  :global(.now-fresh) & {
    border: none;
    margin-top: 0;
  }

  @include at-breakpoint(medium) {
    button {
      font-size: 21px;
      line-height: 32px;
    }
  }

  @include at-breakpoint(large) {
    margin-top: 30px;
    padding-top: 56px;
    grid-column: span 12;
  }
}
