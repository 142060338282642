
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: flex;
  max-width: 100%;
  width: 100%;
  overflow-y: auto;
}

.tab-wrapper {
  display: flex;
  margin: 0 auto;

  :global(.petcurean) & {
    gap: 16px;
  }
}

.tab {
  .tab-button {
    padding: 20px 32px;
    white-space: nowrap;

    :global(.go-solutions) & {
      color: $color-white;
      opacity: 0.8;
      border-bottom: none;
    }

    :global(.now-fresh) & {
      border-bottom: none;
      border-right: none;
    }

    :global(.petcurean) & {
      color: $petcurean-white;
      border-radius: 20px 20px 0 0;
    }

    &:hover,
    &.active {
      :global(.go-solutions) & {
        opacity: 1;
        border-bottom: 4px solid $go-yellow;
      }

      :global(.now-fresh) & {
        background-color: $color-white;

        span {
          font-weight: 400;
        }
      }

      :global(.petcurean) & {
        background-color: $petcurean-dark-orange;
      }
    }
  }

  :global(.now-fresh) & {
    &:last-child {
      .tab-button {
        border-right: 1px solid $now-brown;
      }
    }
  }
}
