
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.suggestions {
  grid-column: 1 / -1;
  margin-bottom: 56px;
  text-align: left;
  margin-left: 16px;

  :global(.go-solutions) & {
    color: $color-white;
  }

  :global(.now-fresh) & {
    box-shadow: none !important; // we don't want the border on the suggestions
    margin-top: 24px;

    @include at-breakpoint(large) {
      margin-top: 0;
    }
  }

  @include at-breakpoint(medium) {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }

  @include at-breakpoint(x-large) {
    display: block;
    margin-bottom: 0;
    grid-column: span 1;
    grid-row: span 1;
  }

  .suggestions-wrapper {
    grid-column: 2 / -1;
  }

  .header {
    @include p1();

    :global(.go-solutions) & {
      text-transform: none;
    }
  }

  .suggestion {
    @include eyebrow();
    font-weight: 700;
    font-size: 18px;
  }

  .suggestion-button {
    cursor: pointer;
    padding: 5px 0;
    margin-top: 5px;
    color: inherit; // fixes iOS Safari bug where the color is not inherited

    :global(.go-solutions) & {
      color: $color-white;
    }
  }
}
