
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.desktop-refinements {
  display: none;
  width: 100%;
  gap: 0px;

  @include at-breakpoint(large) {
    display: flex;
    flex-direction: column;
  }

  :global(.go-solutions) & {
    border-bottom: 1px solid $color-black;
  }

  :global(.now-fresh) & {
    padding: 25px 20px 25px 40px;
    border-bottom: 1px solid $now-brown;
    gap: 16px;
  }

  :global(.petcurean) & {
    border-bottom: 1px solid $petcurean-grey;
    margin-bottom: 24px;
  }
}

.refinementCount {
  @include p2;
  @include bold;
  text-align: center;
  margin: 32px 0 8px;

  :global(.go-solutions) & {
    color: $color-black;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    margin: 0;
    background-color: $now-tan;
    font-size: 20px;
    font-family: 'Stag', sans-serif !important;
    font-weight: 400;
  }

  @include at-breakpoint(large) {
    text-align: start;

    :global(.go-solutions) & {
      margin-top: 40px;
    }

    :global(.petcurean) & {
      margin-top: 36px;
    }
  }
}

.hitCount {
  @include p3;
  text-align: center;
  margin-bottom: 44px;
  position: relative;

  :global(.go-solutions) & {
    color: $go-semidark-grey;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    margin-bottom: 0;
    background-color: $now-tan;
    font-size: 16px;
  }

  @include at-breakpoint(medium) {
    margin-bottom: 54px;
  }

  @include at-breakpoint(large) {
    margin-bottom: 44px;
    text-align: start;

    :global(.go-solutions) & {
      margin-bottom: 22px;
    }

    :global(.petcurean) & {
      margin-bottom: 39px;
    }
  }
}

.mobile-refinements {
  display: flex;
  width: 100%;
  position: relative;
  padding: 23px 34px 18px;
  margin: 0 -16px;
  z-index: $below-nav-z-index;

  @include at-breakpoint(medium) {
    margin: 0 -32px;
  }

  @include at-breakpoint(large) {
    display: none;
  }

  :global(.go-solutions) & {
    border-bottom: 1px solid $go-filter-border;
    background-color: $color-white;
    margin: 0;
  }

  :global(.now-fresh) & {
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    background-color: $now-tan;
    margin: 0;
  }

  :global(.summit) & {
    border-bottom: 1px solid $summit-dark-desaturated-blue;
    background-color: $summit-white;
  }
}

.open-filters-btn {
  @include p2();
  @include bold();
  display: flex;
  align-items: center;
  color: inherit;

  svg {
    margin-inline-start: 6px;

    path {
      :global(.go-solutions) & {
        fill: $color-black;
      }

      :global(.now-fresh) & {
        fill: $now-brown;
      }

      :global(.petcurean) & {
        fill: $color-black;
      }

      :global(.summit) & {
        fill: $summit-blue;
      }
    }
  }

  :global(.now-fresh) & {
    @include h5();
    font-weight: 400;
    color: $now-brown;
    span {
      font-family: 'Stag', sans-serif;
    }
  }

  :global(.summit) & {
    @include h5();
    color: $summit-black;
  }
}
