
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.filters-open {
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    background-color: rgba($color-black, 0.5);
    z-index: 21;

    @include at-breakpoint(large) {
      display: none;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;

  :global(.go-solutions) & {
    background-color: $color-black;
  }

  :global(.now-fresh) & {
    max-width: none;
    width: auto;
    padding: 0;
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    column-gap: 0;
  }

  :global(.petcurean) & {
    background-color: $petcurean-orange;
  }
}

.search-bar {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  padding: 84px 0 20px;

  @include at-breakpoint(medium) {
    padding: 90px 0 30px;
  }

  @include at-breakpoint(large) {
    padding: 100px 0 40px;
  }

  @include at-breakpoint(x-large) {
    padding: 136px 0 70px;
  }
}

.categories {
  grid-column: 1 / -1;
}

.search-results {
  @include grid-container;

  :global(.now-fresh) & {
    max-width: none;
    width: auto;
    padding: 0;
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    column-gap: 0;
  }
}

.facet-filters {
  grid-column: 1 / -1;
  position: fixed;
  left: -50vw;
  height: 100dvh;
  width: 100vw;
  top: 0;
  z-index: 22;
  overflow-y: auto;
  visibility: hidden;
  transition:
    left 0.3s ease,
    visibility 0.3s ease;

  &.is-open {
    left: 0;
    visibility: visible;
  }

  @include reducedMotion() {
    transition: none;
  }

  @include at-breakpoint(medium) {
    width: 50vw;
  }

  @include at-breakpoint(large) {
    z-index: 0;
    position: static;
    visibility: visible;
    width: auto;
    height: auto;
    grid-column: 1 / 5;

    :global(.now-fresh) & {
      border-right: 1px solid $now-brown;
      border-bottom: 1px solid $now-brown;
    }
  }

  @include at-breakpoint(x-large) {
    grid-column: 1 / 4;
  }

  :global(.now-fresh) & {
    background-color: $now-tan;
  }
}

.results-wrapper {
  grid-column: 1 / -1;

  :global(.go-solutions) & {
    text-align: center;
  }

  :global(.petcurean) & {
    margin-top: 24px;
  }

  @include at-breakpoint(large) {
    grid-column: 5 / -1;

    :global(.now-fresh) & {
      border-bottom: 1px solid $now-brown;
    }

    :global(.petcurean) & {
      margin-top: 0;
    }
  }

  @include at-breakpoint(x-large) {
    grid-column: 4 / -1;
    grid-template-columns: repeat(3, 1fr);
  }
}

.refinement-display {
  @include at-breakpoint(large) {
    display: flex;
    grid-column: 1 / -1;
  }
}

.search-hits {
  grid-column: 1 / -1;

  :global(.go-solutions) & {
    text-align: center;
    margin-top: 24px;
  }

  :global(.petcurean) & {
    margin-top: 24px;
  }

  @include at-breakpoint(large) {
    grid-column: 1 / -1;
  }

  @include at-breakpoint(x-large) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.search-hits-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  :global(.go-solutions) & {
    gap: 24px;
    margin-top: 24px;
  }

  :global(.now-fresh) & {
    gap: 1px;
  }

  @include at-breakpoint(medium) {
    grid-template-columns: repeat(2, 1fr);

    :global(.go-solutions) & {
      margin-top: 0;
    }
  }

  @include at-breakpoint(x-large) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.search-hits-list-products {
  :global(.now-fresh) & {
    grid-template-columns: repeat(2, 1fr);

    @include at-breakpoint(x-large) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.search-hits-item {
  // NF Border Logic
  :global(.now-fresh) & {
    box-shadow: 0 0 0 1px $now-brown; // Add border to child elements

    > * {
      border: none; // Remove border from child elements
    }
  }
}

.pagination {
  grid-column: 1 / -1;
  margin: 32px 0 44px 0;

  @include at-breakpoint(large) {
    margin: 66px 0 44px 0;
  }
}

.pagination-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.pagination-item {
  border-radius: 50%;
  border: 1px solid transparent;
}

.pagination-link {
  display: flex;
  flex-shrink: 0;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;

  :global(.go-solutions) & {
    @include p1();
    @include bold();

    &:hover {
      text-decoration: none;
    }
  }

  :global(.petcurean) & {
    @include p1();
    @include bold();
    color: $petcurean-grey;

    &:hover {
      text-decoration: none;
    }
  }
}

.selected-item {
  :global(.go-solutions) & {
    background-color: $color-black;
  }

  :global(.now-fresh) & {
    border-color: $now-brown;
  }

  :global(.petcurean) & {
    background-color: $petcurean-orange;
  }

  .pagination-link {
    :global(.go-solutions) & {
      color: $color-white;
    }

    :global(.petcurean) & {
      color: $petcurean-white;
    }
  }
}
