
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.search-container {
  width: 275px;
  position: relative;

  @include at-breakpoint(medium) {
    width: 550px;
  }

  @include at-breakpoint(x-large) {
    width: 650px;
  }

  .search-form {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    width: inline-flex;
  }

  .search-input {
    width: 100%;
    border-radius: 100px;
    padding: 7px 30px 7px 40px;
    border: 1px solid $color-black;
    background-color: $color-white;

    :global(.go-solutions) & {
      border: none;
    }

    :global(.now-fresh) & {
      border: 1px solid $now-brown;
    }

    :global(.petcurean) & {
      border: 1px solid $petcurean-grey;
    }
  }

  .search-submit {
    position: absolute;
    left: 11px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      width: 17px;

      path {
        :global(.go-solutions) & {
          fill: $go-charcoal;
        }

        :global(.now-fresh) & {
          fill: $now-brown;
        }

        :global(.petcurean) & {
          fill: $petcurean-grey;
        }
      }
    }
  }

  .search-reset {
    position: absolute;
    right: 11px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      * {
        fill: $color-black;

        :global(.go-solutions) & {
          fill: $go-charcoal;
        }

        :global(.now-fresh) & {
          fill: $now-brown;
        }

        :global(.petcurean) & {
          fill: $petcurean-grey;
        }
      }
    }
  }

  .search-loading {
    position: absolute;
    right: 40px;
    width: 17px;
    display: inline-flex;
    align-items: center;

    &[hidden] {
      display: none;
    }

    svg {
      width: 17px;
      animation: spin 1s linear infinite;

      path {
        stroke: $color-black;

        :global(.go-solutions) & {
          stroke: $go-charcoal;
        }

        :global(.now-fresh) & {
          stroke: $now-brown;
        }

        :global(.petcurean) & {
          stroke: $petcurean-grey;
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
