
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: none;

  @include at-breakpoint(large) {
    display: flex;
  }

  :global(.gather) & {
    color: $gather-white;

    @include at-breakpoint(large) {
      color: $petcurean-grey;
    }
  }

  :global(.gather) & {
    background-color: $color-light-grey;
  }

  :global(.go-solutions) & {
    background-color: $go-charcoal;
    color: $color-white;
  }

  :global(.now-fresh) & {
    color: $now-brown;

    @include at-breakpoint(large) {
      outline: 1px solid $now-brown;
    }
  }

  :global(.petcurean) & {
    background-color: $color-light-grey;
    color: $petcurean-white;

    @include at-breakpoint(large) {
      color: $petcurean-grey;
    }
  }

  :global(.summit) & {
    background-color: $color-light-grey;
    color: $summit-white;

    @include at-breakpoint(large) {
      color: $petcurean-grey;
    }
  }

  .wrapper {
    @include flex-container();
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }
}

.buy-cta-link {
  @include p2();
  margin: 0 16px 0 0;

  :global(.go-solutions) & {
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }

  :global(.now-fresh) & {
    padding: 0;
    border: none;
    text-decoration: underline;
    font-family: inherit;

    &:hover {
      border-bottom: none;
    }
  }
}
