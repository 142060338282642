
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container();
  outline: 1px solid $now-brown;
  margin-bottom: 1px;
  max-width: none !important;
  column-gap: 0 !important;
  width: auto;
  padding: 0 !important;
  color: $now-brown;
}

.top-wrapper {
  display: flex;
  flex-direction: column;
  padding: 40px;
  grid-column: span 6;
  border-bottom: 1px solid $now-brown;

  @include at-breakpoint(medium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @include at-breakpoint(large) {
    grid-column: span 12;
    padding: 40px 80px;
  }

  @include at-breakpoint(xlarge) {
    padding: 40px 100px;
  }
}

.eyebrow {
  @include eyebrow-box();
  margin-bottom: 12px;
  text-align: center;
}

.title {
  @include h3();
}

.description {
  padding-top: 20px;
  @include at-breakpoint(medium) {
    padding: 0 80px;
  }
}

.prod-cta {
  min-width: 236px;
  width: fit-content;
  display: inline-block;
  margin-top: 24px;

  @include at-breakpoint(medium) {
    margin-top: 0;
  }
}
