
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  margin: 60px;
}

.headline {
  margin-bottom: 8px;
}
