
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  margin: 10px 0 20px;
  min-height: 50px;

  .header {
    :global(.go-solutions) & {
      @include eyebrow;
      color: $go-gray;
    }

    :global(.now-fresh) & {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.2;
      color: $now-brown;
      opacity: 0.6;
    }

    :global(.petcurean) & {
      @include eyebrow;
      color: $petcurean-white;
      opacity: 0.8;
    }
  }

  .search {
    :global(.go-solutions) & {
      @include h4;
      color: $color-white;
    }

    :global(.now-fresh) & {
      @include font-sizes(18px, 18px, 26px);
      font-weight: 700;
      line-height: 1.2;
      color: $now-brown;
    }

    :global(.petcurean) & {
      @include h4;
      color: $petcurean-white;
    }
  }
}
