
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.move-nav {
  transform: translateX(-100vw);
}

.navbar {
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 88px;
  transition: transform 0.25s;
  z-index: $nav-z-index;

  :global(.gather) &,
  :global(.petcurean) & {
    top: -1px;
  }

  &:before {
    content: '';
    position: absolute;
    visibility: hidden;
    z-index: -1;
    background-color: $color-black;
    opacity: 0;
    top: 88px;
    left: 0;
    height: calc(100vh - 88px);
    right: 0;
    transition:
      opacity 0.25s,
      visibility 0.25s;
  }

  :global(.go-solutions) & {
    a {
      color: $color-white;

      &:hover {
        color: $go-yellow;
      }
    }
  }
}

.overlay {
  &:before {
    visibility: visible;
    opacity: 0.5;
  }
}

.searchOverlay {
  &:before {
    visibility: visible;
    opacity: 0.5;
  }
}

.container {
  @include flex-container();
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.logo-link {
  :global(.now-fresh) & {
    max-width: 170px;

    @include at-breakpoint(medium) {
      max-width: none;
    }
  }

  &:focus {
    .logo-link-wrapper {
      display: block;
      box-shadow: 0 0 2px 2px $outline;
    }
  }

  &.hide-on-mobile {
    display: none;

    @include at-breakpoint(large) {
      display: block;
    }
  }
}

.nav-items-container {
  display: block;
  position: absolute;
  min-height: 0;
  left: 0;
  top: 100%;
  right: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  transition:
    height 0.25s,
    padding 0.25s,
    min-height 0.25s,
    visibility 0.25s;

  @include at-breakpoint(large) {
    display: none;
  }
}

.nav-open {
  height: calc(100vh - 88px);
  visibility: visible;
  transition:
    height 0.25s,
    padding 0.25s,
    min-height 0.25s;
  overflow: visible;

  @include at-breakpoint(medium) {
    min-height: 968px;
  }
}

.nav-items {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 32px 2px 0 2px;

  @include at-breakpoint(medium) {
    padding-top: 104px;
  }
}

.overflow-visible {
  overflow: visible;
}

.nav-items-large {
  display: none;

  @include at-breakpoint(large) {
    display: flex;
    overflow: visible;
    align-items: center;
  }

  &.hide {
    display: none;
  }
}

.nav-items-large-center {
  @include at-breakpoint(large) {
    margin-left: auto;
    margin-right: auto;

    :global(.petcurean) & {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.subnav-background {
  position: absolute;
  min-height: 0;
  top: 100%;
  left: 0;
  right: 0;
  transition: min-height 0.25s;

  :global(.active) & {
    min-height: 300px;
  }
}

.background-image {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.25s ease-in-out 0.1s;

  :global(.locale-he) & {
    right: auto;
    left: 0;
    transform: scaleX(-1);
  }

  @include at-breakpoint(medium) {
    display: flex;
    width: 296px;

    img {
      width: 100%;
    }
  }

  @include at-breakpoint(large) {
    display: none;
  }

  .nav-open & {
    opacity: 0.6;
  }
}

.nav-item {
  @include eyebrow();
  font-weight: 900;
  margin: 20px 0 0 0;
  padding: 20px 16px;
  position: relative;
  z-index: 1;
  transition: all 0.25s;
  display: block;
  color: white;

  &:first-child {
    margin-top: 0;
  }

  @include at-breakpoint(medium) {
    padding: 28px 32px;
  }

  @include at-breakpoint(large) {
    margin: 0 16px 0 0;
    position: static;
    padding: 0;

    :global(.locale-he) & {
      margin: 0 0 0 16px;
    }

    button {
      padding: 2px;
    }

    &:last-child {
      margin-inline-end: 0;
    }
  }

  @include at-breakpoint(x-large) {
    margin: 0 32px 0 0;
    padding: 0;

    :global(.locale-he) & {
      margin: 0 0 0 32px;
    }
  }
}

.buy-cta {
  position: relative;
  margin-inline-start: auto;
  text-transform: uppercase;
  z-index: 2;
  padding: 0;

  @include at-breakpoint(large) {
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
}

.hamburger {
  display: block;
  margin-inline-start: 24px;

  @include at-breakpoint(large) {
    display: none;
  }

  &.hide {
    display: none;
  }
}

.mobile-region-selector {
  margin-top: auto;
  padding: 10px 10px 10px 2px;
  display: flex;
  flex-grow: 0;
  gap: 8px;
  margin-left: 16px;
  align-items: flex-start;
  flex-direction: column;

  :global(.locale-he) & {
    padding: 10px 2px 10px 10px;
  }

  @include at-breakpoint(medium) {
    padding: 16px;
    flex-direction: row;
    align-items: center;

    :global(.locale-he) & {
      padding: 16px;
    }
  }

  @include at-breakpoint(large) {
    display: none;
  }

  a {
    margin-right: 16px;

    :global(.gather) & {
      color: $gather-white;
    }

    :global(.summit) & {
      color: $summit-white;
    }

    &:after {
      :global(.gather) &,
      :global(.summit) & {
        display: none;
      }
    }
  }

  .pipe {
    color: $color-white;
    display: none;

    @include at-breakpoint(medium) {
      display: block;
    }

    :global(.now-fresh) & {
      color: $now-brown;
    }
  }
}

//themes

/* Gather Styles */
:global(.gather) {
  .logo-link {
    svg {
      width: 100px;

      @include at-breakpoint(x-large) {
        width: 130px;
      }
    }
  }

  .background {
    background-color: $gather-green;
  }

  .nav-items {
    padding: 24px 0 0 0;

    @include at-breakpoint(medium) {
      padding: 104px 0 0 0;
    }
  }

  .nav-items-large {
    margin-inline-start: auto;
    margin-inline-end: 30px;
  }

  .nav-items-container {
    background-color: $gather-green;
    min-height: auto;
  }

  .background-image {
    opacity: 1;
    @include at-breakpoint(medium) {
      width: 392px;

      img {
        width: 100%;
      }
    }
  }

  .nav-item {
    color: $gather-white;
    margin: 24px 0 0 0;
    padding: 16px;
    width: 100%;
    @include gather-nav();

    &:first-child {
      margin-top: 0;
    }

    & a {
      color: $gather-white;

      &:hover {
        color: $gather-dark-green;
      }
    }

    &:global(.active) {
      background-color: $gather-dark-green;
      color: $gather-white;
      a {
        @media (hover: hover) {
          &:hover {
            color: $gather-lighter-green;
          }
        }
      }

      @include at-breakpoint(large) {
        background-color: transparent;
        a {
          @media (hover: hover) {
            &:hover {
              color: $gather-dark-green;
            }
          }
        }
      }
    }

    @include at-breakpoint(medium) {
      margin: 20px 0 0 0;
      padding: 20px 40px 20px 30px;
      width: auto;
    }

    @include at-breakpoint(large) {
      margin: 0 40px 0 0;
      padding: 0;

      :global(.locale-he) & {
        margin: 0 0 0 40px;
      }

      &:last-child {
        margin-inline-end: 0;
      }
    }
  }

  .hamburger {
    margin-inline-start: 16px;

    svg {
      path {
        fill: $gather-white;
      }
    }

    @include at-breakpoint(medium) {
      margin-inline-start: 40px;
    }
  }

  .buy-cta {
    text-transform: none;
    font-size: 14px;
    min-width: auto;
    padding: 10px 14px;

    @include at-breakpoint(medium) {
      padding: 18px 31px;
    }

    @include at-breakpoint(large) {
      padding: 10px 14px;
    }

    @include at-breakpoint(x-large) {
      padding: 18px 31px;
    }
  }
}
/* End Gather Styles */

/* Go Solutions Styles */
:global(.go-solutions) {
  .background {
    background-color: $color-black;
  }

  .logo-link {
    path {
      fill: $color-white;
    }

    &:hover {
      path {
        fill: $color-white;
      }
    }
  }

  .nav-items-container {
    background-color: $color-black;
  }

  .nav-item {
    color: $color-white;
    font-size: 21px;
    line-height: 32px;
    font-weight: 900;

    &:global(.active) {
      background-color: $go-charcoal;
    }

    @include at-breakpoint(large) {
      font-size: 14px;
      line-height: 16px;
      @include bold();

      &:global(.active) {
        background-color: black;
      }
    }
  }

  .buy-cta {
    padding: 12px;
    font-weight: 700;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%);

    &::after {
      bottom: -20px;
      right: -20px;
    }

    span {
      font-size: 12px;
      color: $color-white;
    }
  }
}
/* End Go Solutions Styles */

/* Now Fresh Styles */
:global(.now-fresh) {
  .logo-link {
    margin-top: 0;
    margin-right: 6px;
    @include at-breakpoint(medium) {
      margin-right: 0;
    }
  }

  .background {
    background-color: $now-tan;
    outline: 1px solid $now-brown;

    @include at-breakpoint(large) {
      border-top: none;
    }
  }

  .nav-items-container {
    background-color: $now-tan;
    outline: 1px solid $now-brown;

    @include at-breakpoint(large) {
      outline: none;
    }
  }

  .nav-items {
    padding: 96px 0 0 0;

    @include at-breakpoint(medium) {
      padding: 80px 0 0 0;
    }
  }

  .nav-item {
    @include now-fresh-nav();
    transition: background-color 0.25s;

    a {
      color: $now-brown;
      @include font-sizes(20px, 30px, 16px);
      @include line-heights(24px, 32px, 24px);

      &:hover {
        font-weight: 500;
        color: $now-brown;
      }
    }

    &:global(.active) {
      background-color: $color-white;
      outline: 1px solid $now-brown;

      @include at-breakpoint(large) {
        background-color: transparent;
        outline: none;
      }
    }
  }

  .hamburger {
    svg {
      path {
        fill: $now-brown;
      }
    }
    margin-inline-start: 6px;

    @include at-breakpoint(medium) {
      margin-inline-start: 16px;
    }
  }

  .buy-cta {
    padding: 15px 13px;
    @include font-sizes(14px, 16px, 16px);
    line-height: 16px;
    text-transform: none;
  }

  .mobile-region-selector {
    padding-bottom: 20px;

    a {
      border: 0;
      padding-bottom: 0;
    }
  }
}
/* End Now Fresh Styles */

/* Petcurean Styles */
:global(.petcurean) {
  .logo-link {
    svg {
      width: 150px;

      @include at-breakpoint(x-large) {
        width: 220px;
      }
    }
  }

  .background {
    background-color: $petcurean-orange;
  }

  .nav-items {
    padding: 24px 0 0 0;

    @include at-breakpoint(medium) {
      padding: 104px 0 0 0;
    }
  }

  .nav-items-container {
    background-color: $petcurean-orange;
    min-height: auto;
  }

  .background-image {
    opacity: 1;
    @include at-breakpoint(medium) {
      width: 392px;

      img {
        width: 100%;
      }
    }
  }

  .nav-item {
    color: $petcurean-white;
    margin: 24px 0 0 0;
    padding: 16px;
    width: 100%;
    @include petcurean-nav();

    &:first-child {
      margin-top: 0;
    }

    & a {
      color: $petcurean-white;

      &:hover {
        color: $petcurean-dark-tan;
      }
    }

    &:global(.active) {
      background-color: $petcurean-active-nav-bg;
      color: $petcurean-dark-tan;

      @include at-breakpoint(large) {
        background-color: transparent;
      }
    }

    @include at-breakpoint(medium) {
      margin: 20px 0 0 0;
      padding: 20px 40px 20px 30px;
      width: auto;
    }

    @include at-breakpoint(large) {
      margin: 0 40px 0 0;
      padding: 0;

      :global(.locale-he) & {
        margin: 0 0 0 40px;
      }

      &:last-child {
        margin-inline-end: 0;
      }
    }
  }

  .hamburger {
    margin-inline-start: 16px;

    svg {
      path {
        fill: $petcurean-white;
      }
    }

    @include at-breakpoint(medium) {
      margin-inline-start: 24px;
    }
  }

  .buy-cta {
    font-size: 12.34px;
    font-weight: 400;
    line-height: 1.167;
    letter-spacing: 0.88px;
    min-width: auto;
    padding: 13px;

    @include at-breakpoint(medium) {
      font-size: 14px;
      line-height: 1.143;
      letter-spacing: 1px;
      padding: 14px;
    }
  }
}
/* End Petcurean Styles */

/* Summit Styles */
:global(.summit) {
  .logo-link {
    svg {
      width: 115px;

      @include at-breakpoint(medium) {
        width: 175px;
      }
    }
  }

  .background {
    background-color: $summit-blue;
  }

  .nav-items {
    padding: 24px 0 0 0;
    color: $summit-white;

    @include at-breakpoint(medium) {
      padding: 104px 0 0 0;
    }
  }

  .nav-items-large {
    margin-inline-start: auto;
    margin-inline-end: 30px;
  }

  .nav-items-container {
    background-color: $summit-blue;
    min-height: auto;
  }

  .background-image {
    display: none;
  }

  .nav-item {
    color: $summit-white;
    margin: 24px 0 0 0;
    padding: 16px;
    width: 100%;
    @include summit-nav();

    &:first-child {
      margin-top: 0;
    }

    & a {
      color: $summit-white;

      &:hover {
        color: $summit-dark-desaturated-blue;
      }
    }

    &:global(.active) {
      background-color: $summit-dark-blue;
      color: $summit-dark-desaturated-blue;

      @include at-breakpoint(large) {
        background-color: transparent;
      }
    }

    @include at-breakpoint(medium) {
      margin: 20px 0 0 0;
      padding: 20px 40px 20px 30px;
      width: auto;
    }

    @include at-breakpoint(large) {
      margin: 0 40px 0 0;
      padding: 0;

      :global(.locale-he) & {
        margin: 0 0 0 40px;
      }

      &:last-child {
        margin-inline-end: 0;
      }
    }
  }

  .hamburger {
    margin-inline-start: 16px;

    svg {
      path {
        fill: $summit-white;
      }
    }

    @include at-breakpoint(medium) {
      margin-inline-start: 40px;
    }
  }

  .buy-cta {
    font-size: 14px;
    letter-spacing: 1.5px;
    min-width: auto;
    padding: 10px 14px;

    @include at-breakpoint(medium) {
      padding: 18px 31px;
    }

    @include at-breakpoint(large) {
      padding: 10px 14px;
    }

    @include at-breakpoint(x-large) {
      padding: 18px 31px;
    }
  }
}
/* Summit Styles End */
